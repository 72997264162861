
import Vue from "vue";
import { mapGetters } from "vuex";
import ProgramRating from "@/components/MentoringPlan/ProgramRating.vue";

export default Vue.extend({
  name: "MentoringPlanRatingPage",
  components: {
    ProgramRating
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      program: "program/getProgram"
    })
  },
  mounted(): void {
    this.$store
      .dispatch("program/GET_PROGRAM", this.$route.params.id)
      .then(data => {
        if (!data.can_i_rate_it || data.is_rated || data.isUserRatingBanned) {
          this.$router.push(`/mentoring-programs/${data.id}`);
        }
      });
  }
});
